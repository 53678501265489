import { Component, OnInit } from '@angular/core';
import { Survey, SurveyQuery, SurveyType } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-featured-surveys',
  templateUrl: './featured-surveys.component.html',
  styleUrls: ['./featured-surveys.component.scss'],
})
export class FeaturedSurveysComponent implements OnInit {
  screenType = ScreenType;
  mixedSurveys$: Observable<Survey[]>;

  customOptions: OwlOptions = {
    margin: 8,
    autoHeight: true,
    autoWidth: true,
    pullDrag: true,
    autoplay: false,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    nav: false,
    dots: true,
    responsive: {
      0: { items: 1 },
      540: { items: 1 },
      740: { items: 1 },
    },
  };

  constructor(private surveyQuery: SurveyQuery) {}

  ngOnInit(): void {
    let polls$ = this.surveyQuery
      .selectFavoriteSurvey(SurveyType.Poll)
      .pipe(map((survey) => survey.filter((s) => !s.ComingSoon)));
    let quizzes$ = this.surveyQuery
      .selectFavoriteSurvey(SurveyType.Trivia)
      .pipe(map((survey) => survey.filter((s) => !s.ComingSoon)));

    this.mixedSurveys$ = combineLatest([polls$, quizzes$]).pipe(map(([polls, quizzes]) => [...polls, ...quizzes]));
  }

  trackByFn(index: number, item: Survey) {
    return index;
  }
}
