import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-scorecard-nav',
  templateUrl: './scorecard-nav.component.html',
  styleUrls: ['./scorecard-nav.component.scss'],
})
export class ScorecardNavComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
