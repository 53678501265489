<div class="container success-page" *ngIf="transferAccounts$ | async as transferAccounts">
  <div class="border rounded">
    <div class="p-3 d-flex align-items-center justify-content-center">
      <h5 class="text-success mb-0">{{ 'ecash.transfer.success.title' | translate }}</h5>
    </div>
    <hr />
    <div class="success-container">
      <div class="font-size-14 fw-bold my-5">
        <span class="text-primary">{{ transferAccounts.EmailAddress }}</span> {{ 'ecash.transfer.message.success' | translate }}
        <span class="text-success">{{ transferAccounts.TransferAmount | FortressCurrencyPipe : 'USD' }}</span>
      </div>
      <a class="btn btn-primary fw-bold" routerLink="/transfer"> {{ 'ecash.transfer.success.button' | translate }} </a>
    </div>
  </div>
</div>
