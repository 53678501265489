import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, Observable, firstValueFrom } from 'rxjs';
import {
  MemberQuery,
  Address,
  MemberDetails,
  LoggingService,
  ErrorStateService,
  GiftCodeModel,
  GiftCodeService,
  GiftCodeType,
  MemberProduct,
  GiftCodeEntitlementValueType,
  PortalProductsService,
  ProviderActionKind,
  MemberCardService,
  PaymentService,
  PortalProduct,
  MemberService,
  Cart,
  CartQuery,
} from '@fgb/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fgb-cart-checkout-page-portal-products',
  templateUrl: './cart-checkout-page-portal-products.component.html',
  styleUrls: ['./cart-checkout-page-portal-products.component.scss'],
})
export class CartCheckoutPagePortalProductsComponent implements OnInit, OnDestroy {
  // items and cost
  items$: Observable<Cart[]>;
  totalCost: number;
  totalCostSub: Subscription;
  // member details address
  memberDetails: MemberDetails;
  memberDetailsAddress: Address;
  // gift code related variables and price
  disableApplyGiftCodesButton: boolean = false;
  giftCodeInput: string;
  appliedGiftCodes: GiftCodeModel[] = [];
  totalPriceAfterDiscount: number;
  memberProducts: MemberProduct[] = [];
  totalPrice: number;
  totalShipping: number = 0;
  // pay now
  paymentLoading: boolean = false;
  paymentInitialised: boolean;
  orderCompleted: boolean = false;
  // missing member details
  translationSub: Subscription;
  missingDetails: string = '';
  // tax
  totalTax: number = 0;
  // member details
  memberDetail$: Observable<MemberDetails | undefined>;
  memberAddressForm: UntypedFormGroup;
  portalId = '';
  hasSubmittedMemberAddress: boolean = false;
  editingMemberAddress: boolean = false;

  constructor(
    private location: Location,
    private cartQuery: CartQuery,
    private memberQuery: MemberQuery,
    private logService: LoggingService,
    private ngbModal: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private errorService: ErrorStateService,
    private giftCodeService: GiftCodeService,
    private portalProductService: PortalProductsService,
    private memberCardService: MemberCardService,
    private paymentService: PaymentService,
    private translate: TranslateService,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.errorService.clearErrors();

    // get address from member details
    this.getMemberDetailAddress();

    this.items$ = this.cartQuery.selectAllSubscriptionCartData().pipe(
      tap((cartSubscriptions) => {
        if (!!cartSubscriptions.length) {
          this.totalCost = cartSubscriptions.reduce(
            (accumVal, item) => accumVal + (item.productData as PortalProduct).MerchantPrice,
            0
          );

          this.memberProducts = cartSubscriptions.map((sub) => {
            return {
              MemberId: this.memberDetails.MemberId,
              ProductId: (sub.productData as PortalProduct).ProductId,
              MerchantPrice: (sub.productData as PortalProduct).MerchantPrice * 100,
            } as MemberProduct;
          });

          this.portalProductService
            .calculateAdditionalCharges(this.memberProducts, true)
            .pipe(
              tap((charges) => {
                this.totalTax = charges.TotalTax;
                this.totalPrice = this.totalCost + this.totalTax;
              })
            )
            .toPromise();
        } else {
          this.totalCost = 0;
          this.totalPrice = 0;
          this.totalTax = 0;
        }
      })
    );

    this.portalProductService.fetchPortalProducts().toPromise();
  }

  ngOnDestroy() {
    if (this.translationSub) {
      this.translationSub.unsubscribe();
    }
  }

  // go back to previous page from checkout page
  goBack() {
    this.location.back();
  }

  /** Start editing the user's member details address. */
  editMemberAddress() {
    this.editingMemberAddress = true;
  }

  /** Clear the member details address form fields. */
  clearAddressForm(addressForm: UntypedFormGroup) {
    addressForm.patchValue({
      Street: '',
      Town: '',
      County: '',
      Postcode: '',
      Country: '',
      HomeNumber: '',
    });
  }

  /** Takes in a gift code string, checks to see if the code is valid and returns the calculated discount */
  async applyGiftCode() {
    this.errorService.clearErrors();

    if (this.disableApplyGiftCodesButton) {
      return;
    }

    if (!this.giftCodeInput) {
      this.errorService.addError('Gift Code cannot be empty');
      return;
    }

    if (!!this.appliedGiftCodes.filter((code) => code.Code === this.giftCodeInput).length) {
      this.errorService.addError('This gift code has already been applied');
      return;
    }

    if (this.totalPriceAfterDiscount === 0) {
      this.errorService.addError('Gift code cannot be applied if the basket value is 0');
      return;
    }

    if (this.appliedGiftCodes.length > 0) {
      this.errorService.addError('Only one gift code can be applied.');
      return;
    }

    this.disableApplyGiftCodesButton = true;

    firstValueFrom(this.giftCodeService.validateGiftCode(this.giftCodeInput))
      .then((giftCode) => {
        if (
          giftCode.GiftCodeAssignType == GiftCodeType.Product &&
          !this.memberProducts.filter(
            (item) => item.ProductId == giftCode.EntitlementItemId || item.ProductId == giftCode.EntitlementItemId2
          ).length
        ) {
          this.errorService.addError('Gift code does not apply to any of the basket items.');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (
          giftCode.EntitlementValueType == GiftCodeEntitlementValueType.Percentage &&
          !!this.appliedGiftCodes.filter((gc) => gc.EntitlementValueType == GiftCodeEntitlementValueType.Percentage).length
        ) {
          this.errorService.addError('Percentage discount can only be applied once to an order.');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (giftCode.GiftCodeStatus != 0) {
          this.errorService.addError('Gift code has been used');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (!giftCode.Active) {
          this.errorService.addError('Gift code not available.');
          this.disableApplyGiftCodesButton = false;
          return;
        }

        if (this.appliedGiftCodes.length > 0) {
          /** reset basket values and recalculate with added giftcodes */
          this.resetBasketValues();
        }

        if (giftCode.EntitlementValueType === GiftCodeEntitlementValueType.Value) {
          this.appliedGiftCodes.unshift(giftCode);
        } else {
          this.appliedGiftCodes.push(giftCode);
        }

        this.disableApplyGiftCodesButton = false;

        this.appliedGiftCodes.forEach((x) => {
          this._calculateGiftCodeDeduction(x);
        });
      })
      .catch((err) => {
        this.errorService.addError(err);
        this.disableApplyGiftCodesButton = false;
      });
  }
  resetBasketValues() {
    this.totalPriceAfterDiscount = this.totalPrice;
  }
  /** Calculate the new total basket price after Gift Code has been applied */
  private _calculateGiftCodeDeduction(giftCode: GiftCodeModel) {
    this.errorService.clearErrors();
    let discount = 0;

    if (giftCode.GiftCodeAssignType === GiftCodeType.Order) {
      switch (giftCode.EntitlementValueType) {
        // Value
        case GiftCodeEntitlementValueType.Value: {
          discount = giftCode.EntitlementValue;
          break;
        }
        case GiftCodeEntitlementValueType.Percentage: {
          // Percentage
          discount = (giftCode.EntitlementValue / 100) * this.totalPriceAfterDiscount;
          break;
        }
        case GiftCodeEntitlementValueType.NumberOfEntitlements: {
          // Number of Items - Only used for Free
          this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x.Code === giftCode.Code);
          discount = this.totalPriceAfterDiscount;
          break;
        }
        default: {
          this.errorService.addError('Invalid Gift Code');
          break;
        }
      }
    } else if (giftCode.GiftCodeAssignType === GiftCodeType.Shipping) {
      if (this.totalShipping === 0) {
        this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x.Code !== giftCode.Code);
        this.errorService.addError('Gift Code cannot be applied if the shipping is already 0');
        return;
      }

      switch (giftCode.EntitlementValueType) {
        case GiftCodeEntitlementValueType.Value: {
          // Value
          discount = giftCode.EntitlementValue;
          break;
        }
        case GiftCodeEntitlementValueType.Percentage: {
          // Percentage
          discount = (giftCode.EntitlementValue / 100) * this.totalShipping;
          break;
        }
        case GiftCodeEntitlementValueType.NumberOfEntitlements: {
          // Number of Items - Only used for Free
          discount = this.totalShipping;
          break;
        }
        default: {
          this.errorService.addError('Invalid Gift Code');
          break;
        }
      }
    } else if (giftCode.GiftCodeAssignType == GiftCodeType.Product) {
      let numberOfTimesApplied = 0;
      this.memberProducts
        .filter((item) => item.ProductId == giftCode.EntitlementItemId || item.ProductId == giftCode.EntitlementItemId2)
        .forEach((item) => {
          if (numberOfTimesApplied < giftCode.EntitlementValue) {
            numberOfTimesApplied++;
            discount += item.MerchantPrice;
          }
        });
    }
    this.totalPriceAfterDiscount = Math.max(0, this.totalPriceAfterDiscount - discount);
  }
  /** Works out the total basket price after removal of gift code */
  removeGiftCode(code: string) {
    this.errorService.clearErrors();
    let giftCode = this.appliedGiftCodes.find((x) => x.Code === code);

    if (giftCode) {
      this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x !== giftCode);

      /** reset basket values and recalculate with remaining giftcodes */
      this.resetBasketValues();

      if (this.appliedGiftCodes.length > 0) {
        this.appliedGiftCodes.forEach((x) => {
          this._calculateGiftCodeDeduction(x);
        });
      }
    }
  }

  // purchase poral products
  purchasePortalProduct() {
    if (!this.paymentInitialised) {
      this.logService.info('Retrieving provider action response for payment request...');
      this.paymentLoading = true;
      firstValueFrom(
        this.portalProductService.purchase(
          this.memberProducts,
          this.memberDetailsAddress as Address,
          this.appliedGiftCodes,
          undefined,
          true
        )
      )
        .then((response) => {
          this.paymentLoading = false;
          if (response.ProviderActionInformation.ActionKind == ProviderActionKind.None) {
            this.orderCompleted = true;
            this.memberCardService.clearCacheAndFetchNewCards();
          } else {
            this.paymentInitialised = true;
            this.paymentService.process(response, 'paymentDiv', 'portalproducts/failure');
          }
        })
        .catch((error) => {
          this.logService.error(error);
          this.paymentInitialised = false;
          this.paymentLoading = false;
        });
    }
  }

  openPaymentModal(content: any) {
    this.errorService.clearErrors();
    if (!this.memberAddressForm.valid) {
      this.translationSub = this.translate.stream('checkout.missing-address-details').subscribe((data: string) => {
        this.missingDetails = data;
      });
      this.errorService.addError(this.missingDetails);
    } else {
      this.ngbModal.open(content, { size: 'lg' });
      this.errorService.clearErrors();
      this.paymentInitialised = false;
      this.paymentLoading = true;
      this.purchasePortalProduct();
    }
  }

  // cancel button which will close and rebuild the form with the original data
  cancelAndRebuildForm() {
    this.editingMemberAddress = false;

    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.memberDetails = md;
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],
            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            Postcode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
          this.memberDetailsAddress = {
            PortalId: md.PortalId,
            AddressType: md.AddressType,
            ClubId: md.ClubId,
            MemberId: md.MemberId,
            ExternalRefNumber: md.ExternalRefNumber,
            FirstName: md.FirstName,
            Surname: md.Surname,
            AddressName: md.AddressName,
            Street: md.Street,
            Town: md.Town,
            County: md.County,
            Postcode: md.PostCode,
            Country: md.Country,
            HomeNumber: md.HomeNumber,
            EmailAddress: md.EmailAddress1,
          } as Address;
        }
      })
    );
  }

  getMemberDetailAddress() {
    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.memberDetails = md;
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],
            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
          this.memberDetailsAddress = {
            PortalId: md.PortalId,
            AddressType: md.AddressType,
            ClubId: md.ClubId,
            MemberId: md.MemberId,
            ExternalRefNumber: md.ExternalRefNumber,
            FirstName: md.FirstName,
            Surname: md.Surname,
            AddressName: md.AddressName,
            Street: md.Street,
            Town: md.Town,
            County: md.County,
            Postcode: md.PostCode,
            Country: md.Country,
            HomeNumber: md.HomeNumber,
            EmailAddress: md.EmailAddress1,
          } as Address;
        }
      })
    );
  }

  async saveMemberDetailAddress() {
    this.hasSubmittedMemberAddress = true;

    if (this.memberAddressForm.valid) {
      this.memberDetails = { ...this.memberDetails, ...this.memberAddressForm.value };

      this.memberService
        .updateMemberDetailsAsync(this.portalId, this.memberDetails)
        .pipe(
          tap(() => {
            this.memberDetailsAddress = {
              PortalId: this.memberDetails.PortalId,
              AddressType: this.memberDetails.AddressType,
              ClubId: this.memberDetails.ClubId,
              MemberId: this.memberDetails.MemberId,
              ExternalRefNumber: this.memberDetails.ExternalRefNumber,
              FirstName: this.memberDetails.FirstName,
              Surname: this.memberDetails.Surname,
              AddressName: this.memberDetails.AddressName,
              Street: this.memberDetails.Street,
              Town: this.memberDetails.Town,
              County: this.memberDetails.County,
              Postcode: this.memberDetails.PostCode,
              Country: this.memberDetails.Country,
              HomeNumber: this.memberDetails.HomeNumber,
              EmailAddress: this.memberDetails.EmailAddress1,
            } as Address;

            this.editingMemberAddress = false;
            this.errorService.clearErrors();

            // updates tax after member details address is changed if any items are in basket
            if (!!this.memberProducts.length) {
              this.portalProductService
                .calculateAdditionalCharges(this.memberProducts, true)
                .pipe(
                  tap((charges) => {
                    this.totalTax = charges.TotalTax;
                    this.totalPrice = this.totalCost + this.totalTax;
                  })
                )
                .toPromise();
            }
          })
        )
        .toPromise();
    }
  }
}
