import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LoggingService } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketLoadService } from 'src/app/modules/tickets/ticket.service';

@Component({
  selector: 'fgb-change-event-modal',
  templateUrl: './change-event-modal.component.html',
  styleUrl: './change-event-modal.component.scss',
})
export class ChangeEventModalComponent implements OnInit, AfterViewInit {
  events = this.ticketService.events;
  form = new FormGroup({
    event: new FormControl(''),
  });

  activeModal = inject(NgbActiveModal);

  constructor(
    private ticketService: TicketLoadService,
    private logging: LoggingService
  ) {}

  ngOnInit(): void {
    this.ticketService.getEvents('externalref');
    this.form.controls.event.setValue(this.ticketService.selectedEvent()?.ProductCode ?? null);
  }

  ngAfterViewInit(): void {
    let selectedElement = document.querySelector('input[name="event"]:checked');
    if (selectedElement instanceof HTMLElement) {
      selectedElement.focus();
    }
  }

  Submit() {
    let selectedProductCode = this.form.controls.event.value;
    if (selectedProductCode) {
      this.ticketService.updateSelectedEvent(selectedProductCode);
      this.activeModal.close('updated');
      return;
    }

    this.logging.error('Product code is null, unable to update');
    this.Cancel();
  }

  Cancel() {
    this.activeModal.close('cancelled');
  }
}
