import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { fgbAnimations } from '@fgb/portal-component-library/src/lib/shared/animations';
import { TransactionExtended, TransactionExtendedItem, TransactionExtendedQuery, TransactionExtendedService } from '@fgb/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const TransactionExtendedItemMocks: TransactionExtendedItem[] = [
  {
    ProductCode: 'test 1',
    ProductDescription: 'Hot Dog',
    Quantity: 2,
    UnitPrice: 2,
    VATValue: 0,
    TotalPrice: 4,
  },
  {
    ProductCode: 'test 2',
    ProductDescription: 'Peanuts',
    Quantity: 3,
    UnitPrice: 1.5,
    VATValue: 0,
    TotalPrice: 4.5,
  },
  {
    ProductCode: 'test 3',
    ProductDescription: 'Pint',
    Quantity: 1,
    UnitPrice: 5,
    VATValue: 0,
    TotalPrice: 5,
  },
];

export const TransactionExtendedMocks = [
  {
    DiscountAmount: 0,
    PaidEcashAmount: -100,
    PaidCardAmount: 0,
    CardType: 0,
    TillName: '',
    DescriptionText: 'Test Zero X',
    Amount: -100,
    TransactionRef: 30,
    TransactionDate: '2021-12-02T16:31:15.19',
    TransactionDateUTC: '2021-12-02T16:31:15.19Z',
    POS: '0',
    CardBalance: -100,
    AccountBalance: -100,
    AccountNumber: 0,
    ACN: 0,
    ExternalVendorRef: '',
    AccountDescriptionId: 0,
    ActionId: 0,
    TransactionDescription: 'Test Zero X',
    Location: '',
  },
  {
    DiscountAmount: 0,
    PaidEcashAmount: -1,
    PaidCardAmount: 0,
    CardType: 0,
    TillName: '',
    DescriptionText: 'Test Zero X',
    Amount: -1,
    TransactionRef: 29,
    TransactionDate: '2021-12-02T16:31:08.517',
    TransactionDateUTC: '2021-12-02T16:31:08.517Z',
    POS: '0',
    CardBalance: -1,
    AccountBalance: -1,
    AccountNumber: 0,
    ACN: 0,
    ExternalVendorRef: '',
    AccountDescriptionId: 0,
    ActionId: 0,
    TransactionDescription: 'Test Zero X',
    Location: '',
  },
  {
    DiscountAmount: 0,
    PaidEcashAmount: 0,
    PaidCardAmount: 75,
    CardType: 0,
    TillName: '',
    DescriptionText: 'Debit Account (Via Till)',
    Amount: 75.02,
    TransactionRef: 25,
    TransactionDate: '2020-02-06T14:29:25.63',
    TransactionDateUTC: '2020-02-06T14:29:25.63Z',
    POS: '0',
    CardBalance: 75.02,
    AccountBalance: 75.02,
    AccountNumber: 0,
    ACN: 0,
    ExternalVendorRef: '01Z6HUOMHG01U67OONO13APHAJMNR736',
    AccountDescriptionId: 20008,
    ActionId: 0,
    TransactionDescription: 'Debit Account (Via Till)',
    Location: '',
  },
  {
    DiscountAmount: 0,
    PaidEcashAmount: 0,
    PaidCardAmount: 20,
    CardType: 0,
    TillName: '',
    DescriptionText: 'Debit Account (Via Till)',
    Amount: 20,
    TransactionRef: 24,
    TransactionDate: '2020-02-06T09:20:58.903',
    TransactionDateUTC: '2020-02-06T09:20:58.903Z',
    POS: '0',
    CardBalance: 20,
    AccountBalance: 20,
    AccountNumber: 0,
    ACN: 0,
    ExternalVendorRef: '01Z6HUO4DD01U67OOAG6EPU94M1REMKA',
    AccountDescriptionId: 20008,
    ActionId: 0,
    TransactionDescription: 'Debit Account (Via Till)',
    Location: '',
  },
];

@Component({
  selector: 'fgb-split-transaction-item',
  templateUrl: './split-transaction.component.html',
  styleUrls: ['./split-transaction.component.scss'],
  animations: [fgbAnimations],
})
export class SplitTransactionComponent implements OnInit, OnDestroy {
  id: number;
  isOpen: boolean;
  isDisabled: boolean;
  loading$: Observable<boolean>;
  itemDetails$: Observable<TransactionExtendedItem[]>;
  _destroyed$ = new Subject<void>();
  @Input() data: TransactionExtended;

  constructor(
    private transactionExtendedQuery: TransactionExtendedQuery,
    private transactionService: TransactionExtendedService
  ) {}

  ngOnInit(): void {
    if (!this.data) return;
    this.id = this.data.TransactionRef;
    this.isOpen = this.transactionExtendedQuery.hasActive(this.id);
    this.isDisabled = this.transactionExtendedQuery.isDisabled(this.id);
    this.loading$ = this.transactionExtendedQuery.isLoading(this.id);
    this.itemDetails$ = this.transactionExtendedQuery.getItemDetailsByTref(this.id);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.transactionService.toggleActive(this.id);
    this.transactionService.loadItemDetailsByTref(this.id).pipe(takeUntil(this._destroyed$)).subscribe();
  }
}
