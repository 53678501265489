import { NgModule } from '@angular/core';
import { ChangeEventModalComponent } from './change-event-modal/change-event-modal.component';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectedEventComponent } from './selected-event/selected-event.component';
import { CommonModule } from '@angular/common';
import { TicketsComponent } from './tickets/tickets.component';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '@fgb/portal-component-library/src/lib/pipes';
import { NumberInputModule } from '@fgb/portal-component-library/src/lib/shared/number-input';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { EventHeaderComponent } from './event-header/event-header.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [ChangeEventModalComponent, SelectedEventComponent, TicketsComponent, EventHeaderComponent],
  exports: [ChangeEventModalComponent, SelectedEventComponent, TicketsComponent],
  imports: [
    ReactiveFormsModule,
    FGBSharedModule,
    CommonModule,
    LoadingModule,
    NgbAccordionModule,
    PipesModule,
    NumberInputModule,
    DirectivesModule,
    QRCodeModule,
  ],
})
export class TicketsModule {}
