import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';

// ?? PIPES
import { ContentImagePipe } from './pipes/content-image.pipe';
import { FortressCurrencyPipe } from './pipes/fortress-currency.pipe';
import { ManageTicketsComponent } from './components/manage-tickets/manage-tickets.component';
import { ContentFilePipe } from './pipes/content-file.pipe';
import { CreditCardNumberPipe } from './pipes/credit-card-number.pipe';
import { CardTypeImageUrlPipe } from './pipes/card-type-image-url.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { NumberInputModule } from '@fgb/portal-component-library/src/lib/shared/number-input';
import { PipesModule } from '@fgb/portal-component-library/src/lib/pipes';

// ?? DIRECTIVES
import { ImgDefaultDirective } from './directives/img-default.directive';
import { InPopulationDirective } from './directives/in-population.directive';
import { NotInPopulationDirective } from './directives/not-in-population.directive';
import { IsClientTypeDirective } from './directives/is-client-type.directive';
import { IsNotClientTypeDirective } from './directives/is-not-client-type.directive';
import { StickyDirective } from './directives/sticky.directive';
import { InProductCodeDirective } from './directives/in-product-code.directive';
import { NotInProductCodeDirective } from './directives/not-in-product-code.directive';
import { TmCallbackComponent } from './components/manage-tickets/tm-callback/tm-callback.component';
import { HasTagDirective } from './directives/has-tag.directive';
import { CtrlTemplateDirective } from './directives/control-template.directive';
import { NotHasTagDirective } from './directives/not-has-tag.directive';
import { InClubDirective } from './directives/in-club.directive';
import { NotInClubDirective } from './directives/not-in-club.directive';

// ?? COMPONENTS
import { GiftCodesComponent } from './components/gift-codes/gift-codes.component';
import { TmAuthCallbackComponent } from './components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { FgbYoutubePlayerComponent } from './components/providers/youtube/fgb-youtube-player/fgb-youtube-player.component';
import { FgbVimeoPlayerComponent } from './components/providers/vimeo/fgb-vimeo-player/fgb-vimeo-player.component';
import { FgbSVPPlayerComponent } from './components/providers/streamingVideoProvider/fgb-svp-player/fgb-svp-player.component';
import { FgbTwitchPlayerComponent } from './components/providers/twitch/fgb-twitch-player/fgb-twitch-player.component';
import { FgbZoomConferenceComponent } from './components/providers/zoom/fgb-zoom-conference/fgb-zoom-conference.component';
import { FgbBoxcastPlayerComponent } from './components/providers/boxcast/fgb-boxcast-player/fgb-boxcast-player.component';
import { FgbStreamablePlayerComponent } from './components/providers/streamable/fgb-streamable-player/fgb-streamable-player.component';
import { FgbFacebookPlayerComponent } from './components/providers/facebook/fgb-facebook-player/fgb-facebook-player.component';
import { TimeSinceComponent } from './components/time-since/time-since.component';
import { CountdownWatchComponent } from './components/countdown-watch/countdown-watch.component';
import { SportsAllianceCallbackComponent } from './components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { ProviderCallbackComponent } from './components/providers/provider-callback/provider-callback.component';
import { KeyCloakCallbackComponent } from './components/providers/key-cloak/key-cloak-callback/key-cloak-callback.component';
import { SeatGeekCallbackComponent } from './components/providers/seatgeek/seatgeek-callback/seatgeek-callback.component';
import { OtherMediaCallbackComponent } from './components/providers/other-media/other-media-callback/other-media-callback.component';

// ?? MODULES
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { CountryAndRegionsModule } from '@fgb/portal-component-library/src/lib/shared/country-and-regions';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { LoyaltyIconModule } from '@fgb/portal-component-library/src/lib/shared/loyalty-icon';
import { SortBarModule } from '@fgb/portal-component-library/src/lib/shared/sort-bar';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { ConfirmModalModule } from '@fgb/portal-component-library/src/lib/shared/confirm-modal';
import { LostBarcodeModalComponent } from './components/lost-barcode-modal/lost-barcode-modal.component';

const PIPES = [
  ContentImagePipe,
  ContentFilePipe,
  FortressCurrencyPipe,
  CreditCardNumberPipe,
  CardTypeImageUrlPipe,
  SafeHtmlPipe,
  WithLoadingPipe,
];

const DIRECTIVES = [
  ImgDefaultDirective,
  InPopulationDirective,
  NotInPopulationDirective,
  IsNotClientTypeDirective,
  IsClientTypeDirective,
  StickyDirective,
  InProductCodeDirective,
  NotInProductCodeDirective,
  HasTagDirective,
  NotHasTagDirective,
  InClubDirective,
  NotInClubDirective,
  CtrlTemplateDirective,
];

const IMPORT_COMPONENTS = [
  ManageTicketsComponent,
  GiftCodesComponent,
  TmCallbackComponent,
  TmAuthCallbackComponent,
  FgbYoutubePlayerComponent,
  FgbVimeoPlayerComponent,
  FgbSVPPlayerComponent,
  FgbTwitchPlayerComponent,
  FgbZoomConferenceComponent,
  FgbBoxcastPlayerComponent,
  FgbStreamablePlayerComponent,
  TimeSinceComponent,
  FgbFacebookPlayerComponent,
  CountdownWatchComponent,
  SportsAllianceCallbackComponent,
  KeyCloakCallbackComponent,
  OtherMediaCallbackComponent,
  ProviderCallbackComponent,
  SeatGeekCallbackComponent,
  LostBarcodeModalComponent,
];

const EXPORT_COMPONENTS = [
  ManageTicketsComponent,
  TimeSinceComponent,
  FgbYoutubePlayerComponent,
  FgbVimeoPlayerComponent,
  FgbSVPPlayerComponent,
  FgbTwitchPlayerComponent,
  FgbZoomConferenceComponent,
  FgbBoxcastPlayerComponent,
  FgbStreamablePlayerComponent,
  FgbFacebookPlayerComponent,
  CtrlTemplateDirective,
  CountdownWatchComponent,
];

const IMPORT_MODULES = [
  ReactiveFormsModule,
  FormsModule,
  CommonModule,
  NgbModule,
  RouterModule,
  InlineSVGModule,
  GoogleMapsModule,
  ErrorsModule,
  NumberInputModule,
  SortBarModule,
  CountdownModule,
  LoyaltyIconModule,
  LoadingModule,
  DirectivesModule,
  PipesModule,
  CountryAndRegionsModule,
  ConfirmModalModule,
];

const EXPORT_MODULES = [
  LoadingModule,
  GoogleMapsModule,
  PipesModule,
  NumberInputModule,
  CountryAndRegionsModule,
  ConfirmModalModule,
  DirectivesModule,
];

@NgModule({
  declarations: [...IMPORT_COMPONENTS, ...DIRECTIVES, ...PIPES],
  imports: [...IMPORT_MODULES],
  exports: [...EXPORT_COMPONENTS, ...DIRECTIVES, ...PIPES, ...EXPORT_MODULES],
})
export class SharedModule {}
