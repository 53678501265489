<div class="banner-checkout d-none d-lg-flex">
  <h5 class="text-white">Checkout</h5>
</div>
<div class="container">
  <div class="back-mobile-bar d-flex d-lg-none my-2">
    <!-- Back Button -->
    <a
      class="btn btn-primary flex-grow-0 flex-shrink-0 d-flex align-items-center justify-content-center h-100 pe-2 px-3 py-2 fw-bold me-2"
      (click)="goBack()"
    >
      <span class="material-icons align-middle fw-bold text-white">keyboard_arrow_left</span>
    </a>
    <!-- Checkout Title -->
    <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
      <div
        class="d-flex align-items-center justify-content-center bg-white text-primary border border-secondary rounded rounded text-center h-100 p-2"
      >
        <h4 class="mb-0 text-secondary font-size-16">Checkout</h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7 col-12 mt-3">
      <h4 class="text-secondary font-size-14">ITEMS</h4>
      <hr class="w-100" />
      <fgb-cart-item-subscriptions></fgb-cart-item-subscriptions>
      <button class="btn text-primary fw-bold d-none d-lg-block ps-0" (click)="goBack()">
        <span class="font-size-14" aria-hidden="true">&#60; </span><span class="font-size-14">Back</span>
      </button>
    </div>
    <div class="col-lg-5 col-12 mt-3">
      <h4 class="d-none d-lg-block">&nbsp;</h4>
      <!--member details address-->
      <div class="container shadow py-2 mb-3" [ngClass]="{ 'd-none': editingMemberAddress }">
        <div class="position-relative rounded p-2">
          <div class="d-flex justify-content-between">
            <h4 class="text-secondary font-size-14">Address</h4>
          </div>
          <div class="row">
            <div class="col-10">
              <span *ngIf="memberDetail$ | async as member" class="font-size-14 mb-0 d-inline-block text-info">
                <div>{{ member.Street }},</div>
                <div>{{ member.Town }},</div>
                <div>{{ member.County }},</div>
                <div>{{ member.Country }},</div>
                <div>{{ member.PostCode }},</div>
                <div>{{ member.HomeNumber }}</div>
              </span>
            </div>
            <div class="col-2 text-end">
              <a class="cursor-pointer" (click)="editMemberAddress()"
                ><span class="text-black material-icons align-middle">edit</span></a
              >
            </div>
          </div>
        </div>
      </div>
      <!--EO member details address-->
      <!-- EDIT MEMBER DETAILS ADDRESS FORM -->
      <ng-container *ngIf="editingMemberAddress">
        <div class="shadow container mb-3">
          <div class="position-relative p-2">
            <div class="d-flex justify-content-between">
              <h4 class="text-secondary font-size-14 pt-2 mb-3">Address</h4>
              <a
                (click)="clearAddressForm(memberAddressForm)"
                class="text-primary heading-text-one mb-1 pt-2 font-size-14 cursor-pointer fw-bold"
                >Clear Form</a
              >
            </div>
            <form [formGroup]="memberAddressForm" class="font-size-12">
              <!--street-->
              <div class="form-group">
                <label for="Street">
                  <span>Street</span>
                </label>
                <input
                  id="Street"
                  class="form-control"
                  name="Street"
                  type="text"
                  formControlName="Street"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.Street.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid street.</div>
              </div>
              <!--city-->
              <div class="form-group">
                <label for="Town">
                  <span>City</span>
                </label>
                <input
                  id="Town"
                  class="form-control"
                  name="Town"
                  type="text"
                  formControlName="Town"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.Town.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid city.</div>
              </div>
              <!--country and region-->
              <fgbcl-country-and-regions
                [SeparateRow]="true"
                [Form]="memberAddressForm"
                [HasSubmitted]="hasSubmittedMemberAddress"
              ></fgbcl-country-and-regions>
              <!--post number-->
              <div class="form-group">
                <label for="Postcode">
                  <span>Zip Code</span>
                </label>
                <input
                  id="Postcode"
                  class="form-control"
                  name="Postcode"
                  type="text"
                  formControlName="PostCode"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.PostCode.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid zip code.</div>
              </div>
              <!--phone numner-->
              <div class="form-group">
                <label for="Country">
                  <span>Phone Number</span>
                </label>
                <input
                  id="HomeNumber"
                  class="form-control"
                  name="HomeNumber"
                  type="text"
                  formControlName="HomeNumber"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.HomeNumber.errors
                  }"
                  required
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  minlength="8"
                  maxlength="11"
                />
                <div class="invalid-feedback">Please enter a valid phone number.</div>
              </div>
            </form>
            <div class="d-flex justify-content-between my-2 font-size-14">
              <button class="btn btn-primary fw-bold btn-cancel-save" (click)="cancelAndRebuildForm()">Cancel</button>
              <button class="btn btn-success fw-bold btn-cancel-save" (click)="saveMemberDetailAddress()">Save</button>
            </div>
          </div>
        </div>
      </ng-container>
      <!--EO edit member details address-->
      <fgbcl-errors></fgbcl-errors>
      <!--gift code-->
      <!-- <div class="input-group rounded my-3">
        <input type="text" class="form-control border bg-muted gift-code-input font-size-14" [(ngModel)]="giftCodeInput" />
        <div class="input-group-append">
          <button
            class="btn btn-info text-white form-control heading-text-one font-size-14 fw-bold"
            type="button"
            (click)="applyGiftCode()"
            [disabled]="disableApplyGiftCodesButton"
            [translate]="'checkout.apply-code'"
          >
            Apply Gift Code
          </button>
        </div>
      </div> -->
      <!--EO giftcode-->
      <div class="container shadow py-2 mb-2">
        <h4 class="text-secondary font-size-14">Summary</h4>
        <div class="row mb-2">
          <div class="col-6 fw-bold font-size-12"><span>Items</span>:</div>
          <div class="col-6 font-size-12 text-end">
            {{ totalCost / 100 | FortressCurrencyPipe }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 fw-bold font-size-12">Tax:</div>
          <div class="col-6 font-size-12 text-end">
            {{ totalTax / 100 | FortressCurrencyPipe }}
          </div>
        </div>
        <hr class="mx-3" />
        <div class="row">
          <div class="col-6 fw-bold font-size-12"><span>Total</span>:</div>
          <div class="col-6 fw-bold font-size-12 text-end">
            {{ totalPrice / 100 | FortressCurrencyPipe }}
          </div>
        </div>
        <!--start of code addition -->
        <div class="row text-info font-size-12" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
          <div class="col-12 heading-text-two">Discount Code(s):</div>
          <ng-container *ngFor="let giftCode of appliedGiftCodes">
            <div class="col-9 heading-text"><button (click)="removeGiftCode(giftCode.Code)">X</button> {{ giftCode.Code }}</div>
            <div class="col-3 heading-text text-end">
              <span *ngIf="giftCode.EntitlementValueType == 0"
                >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
              >
              <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
              <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
            </div>
          </ng-container>
        </div>
        <!--EO gift code summary-->
      </div>
      <div class="px-0" *ngIf="items$ | async as items">
        <button
          class="btn btn-success fw-bold mt-2 w-100 font-size-14"
          [disabled]="items.length == 0"
          (click)="openPaymentModal(paymentModal)"
        >
          Pay Now
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #paymentModal let-modal>
  <div class="payment-modal">
    <div class="mt-5" *ngIf="paymentLoading">
      <fgbcl-loading></fgbcl-loading>
    </div>

    <!-- Payment Provider Form -->
    <div class="container payment-container">
      <h4 class="text-center mt-4 mb-3 text-primary">Add Payment Details</h4>
      <div class="row">
        <div class="col-6">
          <p class="text-start fw-bold"><span>Total</span><span>:</span></p>
        </div>
        <div class="col-6">
          <p class="text-end">{{ totalPrice / 100 | FortressCurrencyPipe }}</p>
        </div>
      </div>
      <div class="mb-3" id="paymentDiv"></div>
      <button class="btn btn-primary w-100 mb-3 mt-5 font-size-14 fw-bold" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div>

    <ng-container *ngIf="orderCompleted">
      Membership upgraded successfully
      <button class="btn btn-primary w-100 mb-3 mt-5 font-size-14 fw-bold" (click)="modal.dismiss('Cross click')">close</button>
    </ng-container>
  </div>
</ng-template>
