<div class="d-none d-lg-flex banner">
  <h1>{{ 'payment.page.title' | translate }}</h1>
</div>

<div class="container d-lg-none d-block my-4">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="d-flex align-items-center mb-2">
    <h2 class="payment-cards-title my-0 pe-3">{{ 'payment.cards.title' | translate }}</h2>
    <span class="font-size-12">Powered By: <img src="/assets/images/payment-icons/chase.png" /> </span>
  </div>
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
    [cardIssuerProvider]="'payment.provider.name' | translate"
  ></fgbcl-card-token-list>
  <fgbcl-ecash-description></fgbcl-ecash-description>
</div>
