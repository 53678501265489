import { OnInit, Component, Signal } from '@angular/core';
import { LoadedTicketEvent } from 'src/app/modules/tickets/models/loaded-ticket-event.model';
import { TicketLoadService } from 'src/app/modules/tickets/ticket.service';

@Component({
  selector: 'fgb-loaded-tickets',
  templateUrl: './loaded-tickets.component.html',
  styleUrl: './loaded-tickets.component.scss',
})
export class LoadedTicketsComponent implements OnInit {
  selectedEvent: Signal<LoadedTicketEvent | undefined>;
  loadingEvents = this.ticketService.loadingEvents;
  constructor(private ticketService: TicketLoadService) {}

  ngOnInit() {
    this.ticketService.getEvents('externalref');
    this.selectedEvent = this.ticketService.selectedEvent;
  }
}
