<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/nav-logo.svg" draggable="false" />
    </a>

    <a class="nav-item wallet-icon" [routerLink]="['/rewards/wallet']">
      <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.wallet' | translate }}"
        >account_balance_wallet</span
      >
    </a>

    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item fw-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content mt-3">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="material-icons close-btn text-white">cancel</i>
      </button>
      <div>
        <img alt="" class="logo px-2" src="assets/images/logo.svg" draggable="false" />
      </div>
      <ng-container *ngIf="{ memberDetails: memberDetails$ | async, ecash: ecash$ | async } as info">
        <div class="info px-3 mt-3 text-white">
          <h3 class="font-size-20">{{ info.memberDetails.FirstName }} {{ info.memberDetails.Surname }}</h3>
          <div class="font-size-12">{{ 'member.memberid.title' | translate }} - {{ info.memberDetails.MemberId }}</div>
          <hr class="text-white opacity-100" />
          <div class="cash px-2">
            <div class="fw-bold font-size-14 mb-1">{{ info.ecash | FortressCurrencyPipe : '$' }}</div>
            <div class="font-size-14">{{ 'member.ecash.title' | translate }}</div>
          </div>
          <hr class="text-white opacity-100 mb-2" />
        </div>
      </ng-container>
      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <a class="nav-item fw-bold large-item">
          <ng-container
            [ngTemplateOutlet]="accordionNav"
            [ngTemplateOutletContext]="{
              navTitle: 'nav.account',
              icon: 'person',
              children: [
                { title: 'nav.profile', link: '/account', externalLink: false },
                { title: 'nav.account.manager', link: 'nav.account.manager.link', externalLink: true }
              ]
            }"
          ></ng-container>
        </a>
        <a class="nav-item fw-bold large-item">
          <ng-container
            [ngTemplateOutlet]="accordionNav"
            [ngTemplateOutletContext]="{
              navTitle: 'nav.payment',
              icon: 'credit_card',
              children: [
                { title: 'nav.add.hawks.bucks', link: '/hawksbucks', externalLink: false },
                { title: 'nav.payment.card', link: '/payment', externalLink: false },
                { title: 'nav.transfer.hawks.bucks', link: '/transfer', externalLink: false },
                { title: 'nav.transaction.history', link: '/ecashhistory', externalLink: false }
              ]
            }"
          ></ng-container>
        </a>
        <!-- <a class="nav-item" [routerLink]="['/rewards']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">redeem</span>
            <div class="nav-text fw-bold">{{ 'nav.rewards' | translate }}</div>
          </div>
        </a> -->

        <a
          class="nav-item"
          [routerLink]="['/challenges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
          </div>
        </a>
        <a
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold">{{ 'nav.wallet' | translate }}</div>
          </div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/games/quiz']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">extension</span>
            <div class="nav-text fw-bold">{{ 'nav.games' | translate }}</div>
          </div>
        </a>

        <a class="nav-item fw-bold large-item">
          <ng-container
            [ngTemplateOutlet]="accordionNav"
            [ngTemplateOutletContext]="{
              navTitle: 'nav.parking',
              icon: 'local_parking',
              children: [
                { title: 'nav.parking.view', link: 'nav.parking.view.link', externalLink: true },
                { title: 'nav.parking.buy', link: 'nav.parking.buy.link', externalLink: true }
              ]
            }"
          ></ng-container>
        </a>
        <a class="nav-item fw-bold large-item">
          <ng-container
            [ngTemplateOutlet]="accordionNav"
            [ngTemplateOutletContext]="{
              navTitle: 'nav.tickets',
              icon: 'confirmation_number',
              children: [
                { title: 'nav.tickets.view', link: '/tickets/view', externalLink: false },
                { title: 'nav.tickets.transfer', link: '/tickets/transfer', externalLink: false },
                { title: 'nav.tickets.upgrade', link: 'nav.tickets.upgrade.link', externalLink: true },
                { title: 'nav.tickets.buy', link: 'nav.tickets.buy.link', externalLink: true },
                { title: 'nav.tickets.sell', link: 'nav.tickets.sell.link', externalLink: true },
                { title: 'nav.tickets.faq', link: 'nav.tickets.faq.link', externalLink: true }
              ]
            }"
          ></ng-container>
        </a>
        <a class="nav-item fw-bold large-item">
          <ng-container
            [ngTemplateOutlet]="accordionNav"
            [ngTemplateOutletContext]="{
              navTitle: 'nav.membership',
              icon: 'stars',
              children: [
                { title: 'nav.membership', link: 'nav.membership.link', externalLink: true },
                { title: 'nav.membership.renewals', link: 'nav.membership.renewals.link', externalLink: true }
              ]
            }"
          ></ng-container>
        </a>
        <a class="nav-item fw-bold large-item">
          <ng-container
            [ngTemplateOutlet]="accordionNav"
            [ngTemplateOutletContext]="{
              navTitle: 'nav.help',
              icon: 'help',
              children: [
                { title: 'nav.contact.us', link: '/contact', externalLink: false },
                { title: 'nav.faq', link: '/faq', externalLink: false }
              ]
            }"
          ></ng-container>
        </a>

        <a class="nav-item logout-link-div" (click)="logout()">
          <span class="icon material-icons me-2" aria-hidden="true">power_settings_new</span>
          <div class="nav-text fw-bold">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #accordionNav let-children="children" let-icon="icon" let-navTitle="navTitle">
  <div ngbAccordion class="nav-item-title-div w-100">
    <div ngbAccordionItem [collapsed]="true" class="w-100">
      <h2 ngbAccordionHeader class="mt-0">
        <button ngbAccordionButton data-testid="accordion-button" class="w-100 nav-title">
          <span class="icon material-icons me-2" aria-hidden="true">{{ icon }}</span>
          <span class="text-uppercase"> {{ navTitle | translate }}</span>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <ng-container *ngFor="let child of children">
              <ng-container *ngIf="child?.externalLink">
                <a class="nav-child" [innerHTML]="child.link | translate | markdownTranslate"> </a>
              </ng-container>
              <ng-container *ngIf="!child?.externalLink">
                <a
                  class="nav-child"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                  [routerLink]="child.link"
                  >{{ child.title | translate }}</a
                >
              </ng-container>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>
