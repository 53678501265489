import { Component } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-challenges-page',
  templateUrl: './challenges-page.component.html',
  styleUrl: './challenges-page.component.scss',
})
export class ChallengesPageComponent {
  screenSize = ScreenType;
}
