<div *ngIf="barcode$ | async as barcode">
  <div class="p-0 d-flex flex-wrap flex-column justify-content-around align-items-center rounded border">
    <ng-container *ngIf="cardToken$ | async as cardToken">
      <img class="chase-logo" *ngIf="cardToken.CardIssuer == 'chase'" src="/assets/images/payment-icons/chase.png" />
    </ng-container>
    <div class="font-size-10 text-info mt-3">{{ 'barcode.description' | translate }}</div>
    <div class="qr">
      <qrcode
        [qrdata]="barcode.Barcode"
        [width]="120"
        [errorCorrectionLevel]="'M'"
        [elementType]="'svg'"
        [allowEmptyString]="true"
      ></qrcode>
    </div>
    <ng-container *ngIf="member$ | async as member">
      <!-- Not Owner Barcode Body -->
      <div class="w-100 barcode-content-container bg-white">
        <div class="barcode-content mx-3 pb-3">
          <div class="d-flex justify-content-center align-items-center barcode-details mb-4" *ngIf="card$ | async as card">
            <!--Name and ID-->
            <h5 class="mb-1 font-size-14 card-name text-primary" *ngIf="memberDetails$ | async as memberDetails">
              {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
            </h5>
            <div class="font-size-12 text-black">{{ 'member.card.id' | translate }} {{ card.MemberId }}</div>
          </div>
          <div class="d-flex align-item-center barcode-bottom-container">
            <div class="text-black d-flex font-size-14 align-items-center" *ngIf="ecash$">
              <div class="me-1">{{ 'member.card.ecash.balance' | translate }}</div>
              <label class="mb-0">{{ ecash$ | async | FortressCurrencyPipe }}</label>
            </div>
            <button class="ms-3 btn btn-primary d-flex" [routerLink]="'/hawksbucks'" *ngIf="showTickets">
              <div class="icon material-icons me-1">confirmation_number</div>
              <div class="text-white fw-bold">{{ 'barcode.show.tickets' | translate }}</div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <fgb-ecash-button></fgb-ecash-button>
</div>
