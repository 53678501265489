<div class="modal-header">
  <p class="modal-title bold" id="purchase-confirmation-title text-success">
    {{ 'change.event.confirm.text' | translate }}
  </p>
</div>

<form [formGroup]="form" (ngSubmit)="Submit()">
  <div class="modal-body font-default-bold">
    @for (event of events(); track event.ProductCode) {
    <div class="form-check mb-2">
      <input
        class="form-check-input"
        type="radio"
        name="event"
        [id]="event.ProductCode"
        formControlName="event"
        [value]="event.ProductCode"
      />
      <label class="form-check-label fw-normal d-flex flex-column justify-content-between" [htmlFor]="event.ProductCode">
        <span class="font-size-14 bold">{{ event.EventName }}</span>
        <span class="text-info">{{ event.Date | date : 'ccc, MMM dd, YYYY hh:mma' }}</span>
      </label>
    </div>
    }
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light text-danger fw-bold" (click)="Cancel()">
      {{ 'loaded.tickets.cancel' | translate }}
    </button>
    <button type="submit" class="btn btn-success text-white btn-rounded px-4">
      {{ 'loaded.tickets.confirm' | translate }}
    </button>
  </div>
</form>
