<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card border rounded mb-3 mb-lg-0">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="card-id text-start d-flex align-items-center">
        <div class="personal-info">
          <p class="mb-0 card-name" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-12">
            <div>{{ memberCard.ProductCode }}</div>
            <div class="px-1">-</div>
            <div>ID: {{ memberCard.MemberId }}</div>
          </div>
        </div>
      </div>
      <hr />
      <!--Balances-->
      <div class="d-flex justify-content-between align-items-center">
        <div class="card-points text-start border-white" *ngIf="pointsBalance$">
          <label class="font-size-14 mb-1">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
          <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
        </div>
        <!-- <div class="balance-splitter mx-3">|</div> -->
        <div class="card-id text-start border-start ps-4" *ngIf="ecash$">
          <label class="font-size-14 mb-1">{{ ecash$ | async | FortressCurrencyPipe }}</label>
          <div class="font-size-12">{{ 'member.card.credit.balance' | translate }}</div>
        </div>
        <div class="card-account-no text-start border-start ps-4" *ngIf="ecash$">
          <label class="font-size-14 mb-1">{{ memberCard.ACN }}</label>
          <div class="font-size-12">{{ 'member.card.no' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
