import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-account-nav',
  templateUrl: './account-nav.component.html',
  styleUrls: ['./account-nav.component.scss'],
})
export class AccountNavComponent implements OnInit, AfterViewInit {
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      let accountNav = document.getElementsByClassName('account-navbar')[0];
      let children = accountNav.children;
      for (let i = 0; i < children.length; i++) {
        if (children[i].classList.contains('active')) {
          (children[i] as any).scrollIntoViewIfNeeded();
        }
      }
    });
  }
}
