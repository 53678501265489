<div class="container my-4">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <h5 class="my-3">{{ 'account.details.title' | translate }}</h5>
  <fgb-account-details></fgb-account-details>

  <h5 class="my-3 pt-3">{{ 'questions.subheading' | translate }}</h5>
  <fgbcl-edit-questions></fgbcl-edit-questions>

  <a class="card bg-black tm-widget text-white mt-4 w-100 mb-5" href="{{ 'tm.account.manager.link' | translate }}">
    <h5 class="text-uppercase">{{ 'account.tm.widget.title' | translate }}</h5>
    <img src="/assets/images/tm-logo.svg" />
  </a>
</div>
