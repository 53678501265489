<div class="banner" role="banner">
  <h1>{{ 'games.page.predictor.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="predictors$ | async as predictors">
    <div *ngIf="!predictors.length">
      <div class="no-items w-100 text-center">
        <div class="icon material-icons-two-tone no-results-placeholder-icon text-center" aria-hidden="true">flaky</div>
        <div class="no-results-placeholder-title fw-bold text-primary text-uppercase">
          {{ 'predictor.placeholder.title' | translate }}
        </div>
        <div class="no-results-placeholder-text text-info mb-5">{{ 'predictor.placeholder.description' | translate }}</div>
      </div>
    </div>
    <div *ngFor="let predictor of predictors">
      <fgb-survey-item [survey]="predictor"></fgb-survey-item>
    </div>
  </ng-container>
</div>
