<!--Banner-->
<div class="d-none d-lg-block">
  <!--Mixed Loyalty Nav-->
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<!--Back Button-->
<div class="container">
  <div class="text-danger pt-3 cursor-pointer d-none d-lg-block" [routerLink]="'/rewards/raffles'">
    <span class="material-icons align-middle fw-bold pe-2">navigate_before</span>
    <span class="font-size-14 fw-bold">Back</span>
  </div>
</div>

<!--Point Summary Bar-->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/raffles'" [backButtonTitle]="'Raffles'"></fgb-points-summary-bar>
</div>
<!--Lotto Details-->
<fgbcl-lotto-details [lottoId]="id" [showTermsAndConditions]="true"></fgbcl-lotto-details>
