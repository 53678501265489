<div class="card rounded">
  <div *ngIf="member$ | async as member">
    <div>
      <label class="mb-0">{{ 'account.details.email' | translate }}</label>
      <div class="d-flex align-items-center bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ms-3">{{ member.EmailAddress1 }}</span>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
