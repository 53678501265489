<div class="container d-flex align-items-center">
  <div class="card mx-auto">
    <div class="card-body text-center py-3">
      <div class="card-heading">
        <i class="material-icons me-2 text-success">add_card</i>
        <h5 class="text-success">{{ 'ecash.success.page.title' | translate }}</h5>
      </div>
      <div class="mt-4 sub-heading-modal">
        <div class="text-center mt-3">
          {{ 'ecash.success.page.message' | translate }}
        </div>
      </div>
      <div class="btn-container mt-2 d-block">
        <button [routerLink]="['/payment']" type="button" class="btn btn-danger text-white mx-2 mt-4 fw-bold">
          {{ 'ecash.success.page.close.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
