<nav class="nav nav-secondary">
  <a class="nav-item" [routerLink]="['/challenges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <label class="ps-1">{{ 'nav.challenges' | translate }}</label>
    <div class="border-for-label"></div>
  </a>
  <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <label>{{ 'nav.badges' | translate }}</label>
    <div class="border-for-label nav-border-short"></div>
  </a>
</nav>
