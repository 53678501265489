<div role="contentinfo" class="footer text-white text-center">
  <div class="footer-quicklinks bg-info w-100 p-1">
    <div class="d-flex justify-content-center text-white font-size-14 fw-bold">
      <a href="{{ 'hawks.terms.url' | translate }}" target="_blank"> {{ 'hawks.terms.link' | translate }} </a>
      <div class="mx-2">|</div>
      <a href="{{ 'hawks.privacy.url' | translate }}" target="_blank"> {{ 'hawks.privacy.link' | translate }} </a>
    </div>
    <div class="d-flex justify-content-center text-white font-size-14 fw-bold">
      <a href="{{ 'sfa.terms.url' | translate }}" target="_blank"> {{ 'sfa.terms.link' | translate }} </a>
      <div class="mx-2">|</div>
      <a href="{{ 'sfa.privacy.url' | translate }}" target="_blank"> {{ 'sfa.privacy.link' | translate }} </a>
    </div>
  </div>
  <div class="footer-text bg-black text-black d-flex align-items-center justify-content-center w-100">
    <div class="footer-content mt-lg-3 mt-2">
      <img alt="" class="footer-logo" src="assets/images/logo.svg" draggable="false" />
    </div>
  </div>
</div>
