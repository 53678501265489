<div class="d-flex align-items-center">
  <img class="mx-3" width="60" height="60" src="{{ getTeamLogoUrl(event.AwayTeamId) }}" draggable="false" *ngIf="showImage" />

  <div class="datenow">
    <h4 class="away-team-name d-lg-none m-0 font-size-14 mb-1">{{ event?.TeamNames?.AwayTeamName }}</h4>
    <p class="font-size-14 m-0">
      {{ event?.Date | date: 'ccc, MMM dd hh:mma' }}
    </p>
  </div>
</div>
