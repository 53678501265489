<!-- Change Event Section -->
<h5>{{ 'loaded.tickets.page.title' | translate }}</h5>
<p class="font-size-14">{{ 'loaded.tickets.page.desc' | translate }}</p>

@if (loadingEvents()) {
  <div class="card placeholder-card" aria-hidden="true">
    <div class="card-body p-0">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-12"></span>
      </div>
    </div>
  </div>
} @else if (selectedEvent()) {
  <fgb-selected-event></fgb-selected-event>

  <fgb-tickets></fgb-tickets>
} @else {
  <div class="border rounded">
    <div class="font-size-14 events-placeholder">
      {{ 'no.events.placeholder' | translate }}
    </div>
  </div>
}
