import { computed, Injectable, signal } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ApiService } from '@fgb/core';
import { LoadedTicketEvent } from './models/loaded-ticket-event.model';
import { HttpParams } from '@angular/common/http';
import { LoadedTicket } from './models/loaded-ticket.model';
import { LoadedTicketTransactionResponse } from './models/loaded-ticket-transaction-response.model';
import { LoadedTicketTransactionThirdParty } from './models/loaded-ticket-transaction-third-party.model';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class TicketLoadService {
  private _selectedEvent = signal<LoadedTicketEvent | undefined>(undefined);
  selectedEvent = computed(this._selectedEvent);

  private _events = signal<LoadedTicketEvent[]>([]);
  events = computed(this._events);

  private _loadingEvents = signal(true);
  loadingEvents = computed(this._loadingEvents);

  constructor(private apiService: ApiService) {}

  /** Fetches all the events using the datasource
   * and sets the events and selected event properties */
  getEvents(dataSource: string) {
    if (this.events().length > 0) {
      return;
    }

    const startDate = DateTime.now().toFormat('yyyyMMdd');

    this._loadingEvents.set(true);
    let params = new HttpParams().set('dataSource', dataSource).set('startDate', startDate);

    firstValueFrom(this.apiService.get<LoadedTicketEvent>('events?' + params.toString())).then((result) => {
      this._loadingEvents.set(false);
      for (let i = 0; i < result.length; i++) {
        result[i] = Object.assign(new LoadedTicketEvent(), result[i]);
      }

      this._events.set(result);
      if (!this.selectedEvent() && !!result?.length) {
        this._selectedEvent.set(result[0]);
      }
    });
  }

  /** Update the selected event signal using a product code */
  updateSelectedEvent(productCode: string) {
    let newSelectedEvent = this.events().find((event) => event.ProductCode == productCode);
    if (newSelectedEvent) {
      this._selectedEvent.set(newSelectedEvent);
    }
  }

  /** Get all the tickets using the product code */
  getTickets(ProductCode: string) {
    return this.apiService.get<LoadedTicket>('loadedtickets/tickets?=' + ProductCode);
  }

  /** Get the transactions made on a ticket */
  getTicketTransactions(ticketNumber: number) {
    return this.apiService.getAnySingle<LoadedTicketTransactionResponse>('loadedtickets/' + ticketNumber + '/transactions');
  }

  /** Gets third party transaction details
   * Unsure if this is used
   */
  getThirdPartyTransactionDetails(ticketNumber: number, fgbTref: any) {
    return this.apiService.get<LoadedTicketTransactionThirdParty>('loadedtickets/' + ticketNumber + '/transactions/' + fgbTref);
  }

  /** Add funds to the ticket using the customers card */
  load(ticketNumber: number, verify: boolean, AmountInPence: number): Observable<any> {
    return this.apiService.post('loadedtickets/load/' + ticketNumber + '/card/' + verify + '?=' + AmountInPence, null);
  }

  /** Remove funds from ticket and put back on customer card */
  revert(ticketNumber: number, verify: boolean, AmountInPence: number): Observable<any> {
    return this.apiService.post(
      'loadedtickets/revert/' + ticketNumber + '/card/' + verify + '?amountInPence=' + AmountInPence,
      null
    );
  }
}
