<ng-container *ngIf="mixedSurveys$ | async as mixedSurveyItems">
  <ng-container *ngIf="mixedSurveyItems?.length > 0">
    <div class="d-flex justify-content-between align-items-center pb-2 mt-4">
      <h2 class="my-0">{{ 'home.page.featured.games' | translate }}</h2>
      <a
        class="featured-show-all text-nowrap d-flex align-items-center"
        [routerLink]="['/games/quiz']"
        [attr.aria-label]="'featured.games.show.all.screenreader' | translate"
      >
        <span class="featured-text">{{ 'featured.games.show.all' | translate }}</span>
        <span class="font-size-14 icon material-icons ps-2 fw-bold pe-2" aria-hidden="true">arrow_forward_ios</span>
      </a>
    </div>

    <owl-carousel-o *ifScreenSize="screenType.Mobile" [options]="customOptions" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let item of mixedSurveyItems; trackBy: trackByFn">
        <ng-template carouselSlide>
          <fgb-survey-item [survey]="item"></fgb-survey-item>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </ng-container>
</ng-container>
