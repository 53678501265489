import { Component, Input } from '@angular/core';
import { config } from '@fgb/core';
import { LoadedTicketEvent } from 'src/app/modules/tickets/models/loaded-ticket-event.model';

@Component({
  selector: 'fgb-event-header',
  templateUrl: './event-header.component.html',
  styleUrl: './event-header.component.scss',
})
export class EventHeaderComponent {
  showImage = true;
  @Input() event: LoadedTicketEvent | undefined;

  getTeamLogoUrl(teamId: number) {
    return `${config.contentUrl}/image/teams/${teamId}__1_1_1033.png`;
  }
}
