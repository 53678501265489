import { EventModel, TeamNamesViewModel } from '@fgb/core';

export class LoadedTicketEvent implements EventModel {
  ProductCode: string;
  ProductTypeId: number;
  ProductTypeName: string;
  ProductType: number;
  Title: string;
  Date: string | Date;
  GameNumber: number;
  GameType: number;
  Teams: string;
  SeasonId: number;
  EventTypeId: number;
  HomeTeamId: number;
  AwayTeamId: number;
  TicketCount: number;
  /** Object containing the team names as home and away */
  TeamNames: TeamNamesViewModel;

  get EventName() {
    if (this.TeamNames?.HomeTeamName && this.TeamNames?.AwayTeamName) {
      return `${this.TeamNames.HomeTeamName} vs ${this.TeamNames.AwayTeamName}`;
    }
    return this.Teams;
  }
}
