<div class="container my-4">
  <fgb-barcode></fgb-barcode>
</div>

<!--Nav-->
<fgb-home-nav></fgb-home-nav>

<!--Scorecard carousel-->
<fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true" [phaseThree]="true"></fgbcl-scorecard-carousel>

<div class="container">
  <!-- Featured Rewards -->
  <!-- <fgbcl-featured-rewards></fgbcl-featured-rewards> -->
</div>
<!-- Offers -->
<fgbcl-campaigns-offers-list (dataChange)="onCampaignOfferListChange($event)"></fgbcl-campaigns-offers-list>

<div class="container">
  <!-- Featured Games -->
  <fgb-featured-surveys></fgb-featured-surveys>

  <!-- Announcements -->
  <fgbcl-announcements></fgbcl-announcements>
</div>

<!--Vouchers to be used for local testing-->
<!-- <fgb-vouchers></fgb-vouchers> -->
