<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/login-registration/login-banner.png" />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="p-4 right-side-login">
    <form class="change-password container" (submit)="submit()" [formGroup]="formChangePassword">
      <h5 class="change-password-title">{{ 'change.pw.title' | translate }}</h5>
      <p
        class="user-change-password-instruction font-size-14 mb-4"
        [innerHtml]="'user.change.password.instruction' | translate"
      ></p>
      <div class="form-input mb-3">
        <div class="form-floating position-relative">
          <input class="form-control" [type]="visiblePassword ? 'text' : 'password'" formControlName="password" />
          <label>{{ 'change.pw.label' | translate }}</label>
          <span
            class="visible-icon material-icons cursor-pointer text-info"
            (click)="toggleVisiblePassword()"
            *ngIf="visiblePassword"
          >
            visibility
          </span>
          <span
            class="visible-icon material-icons cursor-pointer text-info"
            (click)="toggleVisiblePassword()"
            *ngIf="!visiblePassword"
          >
            visibility_off
          </span>
        </div>
        <div class="text-danger font-size-12 mt-2" *ngIf="checkForm['password']">
          {{ 'change.pw.warn.valid' | translate }}
        </div>
      </div>
      <div class="form-input mb-3">
        <div class="form-floating position-relative">
          <input class="form-control" [type]="visibleConfirm ? 'text' : 'password'" formControlName="confirmPassword" />
          <label>{{ 'change.pw.label.confirm' | translate }}</label>
          <span
            class="visible-icon material-icons cursor-pointer text-info"
            (click)="toggleVisibleConfirm()"
            *ngIf="visibleConfirm"
          >
            visibility
          </span>
          <span
            class="visible-icon material-icons cursor-pointer text-info"
            (click)="toggleVisibleConfirm()"
            *ngIf="!visibleConfirm"
          >
            visibility_off
          </span>
        </div>
        <div class="text-danger font-size-12 mt-2" *ngIf="checkForm['confirmPassword']">
          {{ 'change.pw.warn.confirm' | translate }}
        </div>
      </div>
      <div class="btn-submit action-buttons mt-lg-4 mt-auto">
        <button
          type="submit"
          [disabled]="formChangePassword.invalid || !isPasswordSameConfirm"
          class="w-100 btn btn-primary fw-bold"
        >
          {{ 'change.pw.btn' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
<ng-template #alert let-modal>
  <div class="modal-body">
    <div class="text-center mt-4">{{ contentModal }}</div>
  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn btn-success" (click)="modal.close()">OK</button>
  </div>
</ng-template>
