<div class="container my-4">
  <fgb-barcode [showTickets]="false"></fgb-barcode>
</div>

<!--Nav-->
<fgb-home-nav></fgb-home-nav>

<div class="container mb-4">
  <div class="transfer-image text-white" [routerLink]="'/transfer'">
    <h5 class="mb-1">{{ 'hawks.banner.transfer.title' | translate }}</h5>
    <div class="font-size-12">{{ 'hawks.banner.transfer.desc' | translate }}</div>
  </div>
</div>

<div class="container">
  <fgb-loaded-tickets></fgb-loaded-tickets>
</div>
