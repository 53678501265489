import { Component, inject, Signal, signal, TemplateRef } from '@angular/core';
import { TicketLoadService } from '../ticket.service';
import { firstValueFrom, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { LoadedTicket } from '../models/loaded-ticket.model';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { LoadedTicketTransaction } from '../models/loaded-ticket-transaction-response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { LoadedTicketEvent } from 'src/app/modules/tickets/models/loaded-ticket-event.model';
import { BalanceQuery, BalanceService, config } from '@fgb/core';

@Component({
  selector: 'fgb-tickets',
  templateUrl: './tickets.component.html',
  styleUrl: './tickets.component.scss',
})
export class TicketsComponent {
  tickets = signal<LoadedTicket[]>([]);
  selectedEvent: Signal<LoadedTicketEvent | undefined>;
  ticketDetails = signal<LoadedTicket | undefined>(undefined);
  loading = signal(true);
  loadingTransactions = signal(false);
  submitted = signal(false);
  balance = signal<number | undefined>(undefined);

  constructor(
    private ticketService: TicketLoadService,
    private balanceService: BalanceService,
    private balanceQuery: BalanceQuery
  ) {
    this.selectedEvent = this.ticketService.selectedEvent;
    let selectedEvent$ = toObservable(this.ticketService.selectedEvent);
    selectedEvent$
      .pipe(
        takeUntilDestroyed(),
        tap(() => this.loading.set(true)),
        switchMap((selectedEvent) => {
          if (!selectedEvent?.ProductCode) {
            return of([]);
          }

          return this.ticketService.getTickets(selectedEvent.ProductCode).pipe(
            map((tickets) => {
              return tickets.sort((a: any, b: any) => {
                return b.RealBalanceInPence - a.RealBalanceInPence;
              });
            }),
            tap(() => this.loading.set(false)),
            startWith([])
          );
        })
      )
      .subscribe((tickets) => {
        this.tickets.set(tickets);
      });

    this.balanceQuery
      .selectPurse(config.purseConfig.combined)
      .pipe(takeUntilDestroyed())
      .subscribe((balance) => this.balance.set(balance));
  }

  submit(amountInPence: number, ticketNumber: number, content: TemplateRef<any>) {
    const amount = amountInPence * 100;

    this.submitted.set(true);
    firstValueFrom(this.ticketService.load(ticketNumber, false, amount))
      .then(() => {
        let foundTicket = this.tickets().find((ticket) => ticket.acn === ticketNumber);
        if (!foundTicket) {
          return;
        }

        foundTicket.RealBalanceInPence += amount;
        this.balanceService.debitBalance(config.purseConfig.combined, amountInPence);
        this.openModal(content);
      })
      .catch((err) => console.error(err))
      .finally(() => this.submitted.set(false));
  }

  revert(ticketNumber: number, content: TemplateRef<any>) {
    if (this.submitted()) {
      return;
    }

    this.submitted.set(true);
    let foundTicket = this.tickets().find((ticket) => ticket.acn === ticketNumber);
    if (!foundTicket) {
      return;
    }
    let amountToRevert = foundTicket.RealBalanceInPence;

    firstValueFrom(this.ticketService.revert(ticketNumber, false, amountToRevert))
      .then(() => {
        foundTicket!.RealBalanceInPence = 0;
        this.balanceService.addBalance(config.purseConfig.combined, amountToRevert / 100);
        this.openModal(content);
      })
      .finally(() => this.submitted.set(false));
  }

  // Hacky stuff below
  private modalService = inject(NgbModal);
  transactionDetails$: Observable<LoadedTicketTransaction[]>;
  screenType = ScreenType;
  topUpAmount = 1;

  //modal popup call
  openTransactionsModal(ticketNumber: number, content: TemplateRef<any>) {
    this.loadingTransactions.set(true);
    this.transactionDetails$ = this.ticketService.getTicketTransactions(ticketNumber).pipe(
      map((transactions) => transactions.Details),
      tap(() => this.loadingTransactions.set(false)),
      startWith([])
    );

    this.openModal(content);
  }

  openModal(content: TemplateRef<any>) {
    return this.modalService.open(content, { centered: true, windowClass: 'dark-modal' });
  }

  openTicketDetails(ticketNumber: number, content: TemplateRef<any>) {
    this.ticketDetails.set(this.tickets().find((ticket) => ticket.acn === ticketNumber));

    let modal = this.openModal(content);
    modal.result.finally(() => {
      this.ticketDetails.set(undefined);
    });
  }
}
