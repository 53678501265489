<ng-container *ifScreenSize="screenSize.Desktop">
  <div class="banner" role="banner"></div>
</ng-container>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="polls$ | async as polls">
    <div *ngIf="!polls.length">
      <div class="no-items w-100 text-center">
        <div class="icon material-icons-two-tone no-results-placeholder-icon text-center" aria-hidden="true">poll</div>
        <div class="no-results-placeholder-title fw-bold text-primary text-uppercase">
          {{ 'polls.placeholder.title' | translate }}
        </div>
        <div class="no-results-placeholder-text text-info mb-5">{{ 'polls.placeholder.description' | translate }}</div>
      </div>
    </div>
    <div *ngFor="let poll of polls">
      <fgb-survey-item [survey]="poll"></fgb-survey-item>
    </div>
  </ng-container>
</div>
