<div class="d-none d-lg-block">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<div class="container">
  <div class="text-danger pt-3 cursor-pointer d-none d-lg-block" [routerLink]="'/rewards'">
    <span class="material-icons align-middle fw-bold pe-2">navigate_before</span>
    <span class="font-size-14 fw-bold">Back </span>
  </div>
</div>

<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar
    [backButtonLink]="marketplaceItemType.backLink"
    [marketplaceItemGroup]="marketplaceItemType.group"
  ></fgb-points-summary-bar>
</div>

<fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>
