import { Component, inject, OnInit, Signal } from '@angular/core';
import { TicketLoadService } from '../ticket.service';
import { LoadedTicketEvent } from '../models/loaded-ticket-event.model';
import { ChangeEventModalComponent } from '../change-event-modal/change-event-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-selected-event',
  templateUrl: './selected-event.component.html',
  styleUrl: './selected-event.component.scss',
})
export class SelectedEventComponent implements OnInit {
  selectedEvent: Signal<LoadedTicketEvent | undefined>;
  showImage = true;
  private modalService = inject(NgbModal);

  constructor(private ticketService: TicketLoadService) {}
  ngOnInit(): void {
    this.selectedEvent = this.ticketService.selectedEvent;
  }

  openChangeEventModal() {
    this.modalService.open(ChangeEventModalComponent, {
      centered: true,
      scrollable: true,
      windowClass: 'dark-modal',
    });
  }
}
