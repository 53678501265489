@if (loading()) {
  <div class="card placeholder-card" aria-hidden="true">
    <div class="card-body p-0">
      <div class="card-text placeholder-glow">
        <p class="placeholder col-12"></p>
        <p class="placeholder col-12"></p>

        <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-12 mb-3 btn-placeholder"></a>

        <p class="placeholder col-6"></p>
        <p class="placeholder col-6"></p>
      </div>
    </div>
  </div>
} @else {
  <div *ngFor="let fqa of tickets(); index as i">
    <div ngbAccordion #acc="ngbAccordion" [destroyOnHide]="false" class="mt-3 w-100 mt-lg-0">
      <div ngbAccordionItem #accItem="ngbAccordionItem" class="ticket-container">
        <h6 ngbAccordionHeader>
          <div class="m-2">
            <div class="d-lg-none d-xl-block">
              <ng-container *ngIf="fqa.SpecialOffer !== null">
                <div class="row fw-bold mb-2">
                  <div class="col-5 specialoffers">
                    <span class="icon material-icons text-danger">grade</span>{{ 'loaded.tickets.offer' | translate }}
                  </div>
                  <div class="col-7 align-items-end family">{{ 'loaded.tickets.family.ticket' | translate }}</div>
                </div>
                <hr />
              </ng-container>
            </div>
          </div>
          <div ngbAccordionHeader>
            <div class="seating-info mb-4">
              <div class="seating-section">
                <span class="font-size-12 fw-bold">{{ 'loaded.tickets.section' | translate }} </span>
                <span class="font-size-14">{{ fqa.area }}</span>
              </div>
              <div class="seating-section">
                <span class="font-size-12 fw-bold">{{ 'loaded.tickets.row' | translate }} </span>
                <span class="font-size-14">{{ fqa.row }}</span>
              </div>
              <div class="seating-section">
                <span class="font-size-12 fw-bold">{{ 'loaded.tickets.seat' | translate }} </span>
                <span class="font-size-14"> {{ fqa.seat }}</span>
              </div>
            </div>
            <div class="seating-info">
              <div class="seating-section">
                <span class="font-size-12 fw-bold">{{ 'loaded.tickets.ticket' | translate }} </span
                ><span class="font-size-14"> {{ fqa.acn }}</span>
              </div>
              <div class="col-lg-3 col-6" *ifScreenSize="screenType.Desktop">
                <button class="d-flex align-items-center btn pt-3" (click)="openTransactionsModal(fqa.acn!, expandContent)">
                  <span class="loaded-icon icon material-icons text-primary me-2" aria-hidden="true">open_in_new</span>
                  <span class="text-primary font-size-12">{{ 'expand.transactions' | translate }}</span>
                </button>
              </div>
              <div class="">
                <div class="">
                  <span class="fw-bold text-success font-size-14">{{ fqa.RealBalanceInPence / 100 | FortressCurrencyPipe }}</span>
                </div>
              </div>
            </div>

            <div class="btn-add-hawks">
              <div *ngIf="fqa.RealBalanceInPence === 0">
                <button
                  type="button"
                  ngbAccordionToggle
                  class="btn fw-bold mx-auto border-0 rounded"
                  [ngClass]="{
                    'btn-primary text-white  manage-button': accItem.collapsed,
                    'text-secondary d-lg-block d-none cancel-button': !accItem.collapsed,
                  }"
                >
                  {{ accItem.collapsed ? 'Add Hawks Bucks' : 'Cancel' }}
                </button>
              </div>
              <ng-container *ngIf="fqa.RealBalanceInPence > 0">
                <button
                  ngbAccordionToggle
                  type="button"
                  class="btn fw-bold mx-auto border-0 rounded"
                  [ngClass]="{
                    'btn-black text-white manage-button': accItem.collapsed,
                    'text-secondary d-lg-block d-none cancel-button': !accItem.collapsed,
                  }"
                >
                  {{ accItem.collapsed ? 'Manage' : 'Cancel' }}
                </button></ng-container
              >
            </div>

            <div class="" *ngIf="accItem.collapsed">
              <div class="d-flex align-items-center extra-info-container">
                <div class="">
                  <button class="d-flex align-items-center btn p-0" (click)="openTransactionsModal(fqa.acn!, expandContent)">
                    <span class="loaded-icon icon material-icons text-primary me-2" aria-hidden="true">open_in_new</span>
                    <span class="text-primary font-size-12">{{ 'expand.transactions' | translate }}</span>
                  </button>
                </div>
                <div class="">
                  <button
                    class="d-flex align-items-center btn btn-white p-0"
                    (click)="openTicketDetails(fqa.acn!, ticketDetailsModal)"
                  >
                    <span class="loaded-icon icon material-icons text-black me-2" aria-hidden="true">confirmation_number</span>
                    <span class="text-black font-size-12">{{ 'show.tickets' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </h6>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="p-0">
            <ng-template>
              <div>
                <ng-container>
                  <div class="loaded-tickets-button-container p-0">
                    <div class="mb-3 mt-3 mb-lg-0 justify-content-center align-items-center">
                      <div class="number-input-container mx-auto">
                        <fgbcl-number-input
                          [isActive]="true"
                          [minValue]="1"
                          [maxValue]="balance()!"
                          [incrementValue]="1"
                          [minMessage]="'$1 is the minimum'"
                          [maxMessage]="'You do not have enough'"
                          [(amount)]="topUpAmount"
                        >
                        </fgbcl-number-input>
                      </div>
                    </div>
                    <button
                      class="btn add-balance-button btn-success text-white"
                      data-bs-toggle="modal"
                      role="button"
                      [disabled]="balance() == undefined || topUpAmount > balance()!"
                      (click)="openModal(confirmContent)"
                    >
                      {{ 'loaded.add.button' | translate }}
                    </button>
                  </div></ng-container
                >
              </div>

              <ng-container *ngIf="fqa.RealBalanceInPence > 0">
                <hr *ifScreenSize="screenType.Desktop" />
                <div class="mt-4 mb-2">
                  <a class="d-flex align-items-center revert" (click)="revert(fqa.acn!, successModal)">
                    <span class="icon material-icons text-danger">rotate_left</span
                    ><span class="text-danger fw-bold font-size-14">{{ 'loaded.tickets.revert' | translate }}</span></a
                  >
                </div>
              </ng-container>

              <div class="col-lg-2 col-12">
                <div *ngIf="fqa.RealBalanceInPence === 0">
                  <button
                    type="button"
                    ngbAccordionToggle
                    class="btn fw-bold mx-auto border-0 rounded"
                    [ngClass]="{
                      'btn-danger text-white  manage-button': accItem.collapsed,
                      'text-danger mobile-cancel-button': !accItem.collapsed,
                    }"
                  >
                    {{ accItem.collapsed ? 'Add Club' : 'Cancel' }}
                  </button>
                </div>
                <ng-container *ngIf="fqa.RealBalanceInPence > 0">
                  <button
                    ngbAccordionToggle
                    type="button"
                    class="btn fw-bold mx-auto border-0 rounded"
                    [ngClass]="{
                      'btn-primary text-white manage-button': accItem.collapsed,
                      'text-danger  mobile-cancel-button': !accItem.collapsed,
                    }"
                  >
                    {{ accItem.collapsed ? 'Manage Club' : 'Cancel' }}
                  </button></ng-container
                >
              </div>
            </ng-template>
          </div>
        </div>
        <ng-container *ngIf="fqa.SpecialOffer !== null">
          <hr />
          <div class="d-none d-lg-block">
            <div class="row fw-bold mb-2 mx-2">
              <div class="col-3 specialoffers d-flex align-items-center">
                <span class="icon material-icons text-danger">grade</span>
                <span>{{ 'loaded.tickets.offer' | translate }}</span>
              </div>
              <div class="col-3 family">{{ 'loaded.tickets.family.ticket' | translate }}</div>
              <div class="col-3"></div>
              <div class="col-3 ps-5">
                <label>{{ 'loaded.tickets.presented.by' | translate }}</label>
                <a>
                  <img
                    alt=""
                    width="80"
                    height="40"
                    class="ms-1"
                    aria-labelledby="homeLink"
                    src="assets/icons/fortresssmall.png"
                    draggable="false"
                /></a>
              </div>
            </div>
          </div>

          <div class="row pb-2 presentedby">
            <div>
              <label class="fortress">{{ 'loaded.tickets.presented.by' | translate }}</label>
              <a>
                <img
                  alt=""
                  width="80"
                  height="40"
                  class="ms-1"
                  aria-labelledby="homeLink"
                  src="assets/icons/fortresssmall.png"
                  draggable="false"
              /></a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="fqa.RealBalanceInPence > 0">
      <hr class="hr-line" />
    </div>

    <!-- All modal popups here -->
    <!-- Confirm Modal Content -->

    <ng-template #confirmContent let-modal>
      <div class="modal-content">
        <div class="modal-header fw-bold transaction-text">
          <p class="modal-title" id="purchase-confirmation-title text-success pe-3">
            {{ 'loaded.tickets.confirm.text' | translate }}
            <span class="text-success"> {{ topUpAmount | FortressCurrencyPipe }} </span
            >{{ 'loaded.tickets.confirm.text2' | translate }}
          </p>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body load-modal-container">
          <div class="font-size-14 text-black mb-4 rewards-confirmation-additional">
            {{ 'loaded.tickets.confirmation.additional' | translate }}
          </div>

          <div class="load-section-modal-container pb-4">
            <div class="load-section-details-modal">
              <div class="font-size-12 fw-bold text-black transaction-text">{{ 'loaded.tickets.section' | translate }}</div>
              <div class="font-size-14 transaction-subtext">{{ fqa.acn }}</div>
            </div>
            <div class="load-section-details-modal">
              <div class="font-size-12 fw-bold text-black transaction-text">{{ 'loaded.tickets.row' | translate }}</div>
              <div class="font-size-14 transaction-subtext">{{ fqa.row }}</div>
            </div>
            <div class="load-section-details-modal">
              <div class="font-size-12 fw-bold text-black transaction-text">{{ 'loaded.tickets.seat' | translate }}</div>
              <div class="font-size-14 transaction-subtext">{{ fqa.seat }}</div>
            </div>

            <div class="load-section-details-modal">
              <div class="font-size-12 fw-bold text-black transaction-text">{{ 'loaded.tickets.ticket' | translate }}</div>
              <div class="font-size-14 transaction-subtext">{{ fqa.BarcodeText }}</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-light text-danger fw-bold" (click)="this.modal.close()">Cancel</button>
          <button
            class="btn text-white btn-success modal-confirm btn-rounded px-4 fw-bold"
            (click)="submit(topUpAmount, fqa.acn!, successModal); modal.dismiss('Cross click')"
          >
            {{ 'loaded.tickets.confirm' | translate }}
          </button>
        </div>
      </div>
    </ng-template>

    <!-- Transaction Success Modal Content  -->
    <ng-template #successModal let-modal>
      <div class="modal-header">
        <p class="modal-title fw-bold text-success" id="purchase-confirmation-title">
          {{ 'loaded.ticket.transaction.header' | translate }}
        </p>
      </div>
      <div class="modal-body">
        <div class="font-size-14">
          {{ 'loaded.ticket.transaction' | translate }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn add-balance-button btn-success ok-button text-white" (click)="modal.close()">
          {{ 'loaded.tickets.ok' | translate }}
        </button>
      </div>
    </ng-template>

    <!-- Expand Transactions Modal Content -->
    <ng-template #expandContent let-modal>
      <div class="modal-header">
        <p class="modal-title transaction-text" id="purchase-confirmation-title text-success">
          {{ 'loaded.tickets.transaction.details.title' | translate }}
        </p>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>

      <div class="modal-body transactions pb-5">
        @if (transactionDetails$ | async; as transactions) {
          @if (transactions.length) {
            @for (transaction of transactions; track transaction.fgB_TRef) {
              <div class="mb-0">
                <div class="card my-1 border-0">
                  <div class="row align-items-center">
                    <div class="col-8 text-start">
                      <div class="text-black transaction-text font-size-14">
                        {{ transaction.TransDescription }}
                      </div>

                      <div class="font-size-12 text-info mt-2">
                        {{ transaction.TransDate | date: 'MMM dd, yyyy - h:mm a' }}
                      </div>
                    </div>
                    <div class="col-4 text-end">
                      <div
                        class="fw-bold transaction-text text-capitalize font-size-14"
                        [ngClass]="transaction.TotalAmount >= 0 ? 'text-success' : 'text-danger'"
                      >
                        {{ transaction.TotalAmount >= 0 ? '+' : '' }}{{ transaction.TotalAmount | FortressCurrencyPipe }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            }
          } @else if (loadingTransactions()) {
            <fgbcl-loading></fgbcl-loading>
          } @else {
            <p class="text-center">{{ 'loaded.tickets.transaction.details.placeholder' | translate }}</p>
          }
        }
      </div>
    </ng-template>

    <!-- Revert Balance Modal Content -->
    <!-- <ng-template #revertBalanceContent let-modal>
      <div class="modal-header">
        <p class="modal-title" id="purchase-confirmation-title text-success">
          {{ 'loaded.tickets.revert.funds.text.heading' | translate }}
        </p>
        <button type="button" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container>
          <div class="font-size-14 text-black fw-bold ps-3 mb-4 rewards-confirmation-additional">
            {{ 'loaded.tickets.revert.funds.text.desc' | translate }}{{ balance | FortressCurrencyPipe }}
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn add-balance-button btn-success"
          (click)="revert(fqa.acn!, successModal); modal.dismiss('Cross click')"
        >
          {{ 'loaded.tickets.ok' | translate }}
        </button>
      </div>
    </ng-template> -->

    <ng-template #ticketDetailsModal let-modal>
      <div class="header w-100 text-white bg-black py-1 d-flex align-items-center justify-content-between barcode-header">
        <fgb-event-header [event]="selectedEvent()"></fgb-event-header>
        <div class="me-1 ticket-details-close">
          <button type="button" class="modal-close" aria-label="Close" (click)="modal.dismiss('sad')">
            <span class="material-icons text-white" aria-hidden="true">cancel</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        @if (ticketDetails()?.BarcodeText) {
          <div class="d-flex flex-column align-items-center">
            <qrcode
              [qrdata]="ticketDetails()!.BarcodeText"
              [width]="120"
              [errorCorrectionLevel]="'M'"
              [elementType]="'svg'"
              [allowEmptyString]="true"
            ></qrcode>
            <span class="font-size-10 text-info">{{ 'loaded.tickets.modal.barcode.info' | translate }}</span>
          </div>
        }
      </div>
      <div class="bg-black text-white p-3" *ngIf="ticketDetails()">
        <div class="seating-info mb-4">
          <div class="seating-section">
            <span class="font-size-12 fw-bold">{{ 'loaded.tickets.section' | translate }} </span>
            <span class="font-size-14">{{ ticketDetails()!.area }}</span>
          </div>
          <div class="seating-section">
            <span class="font-size-12 fw-bold">{{ 'loaded.tickets.row' | translate }} </span>
            <span class="font-size-14">{{ ticketDetails()!.row }}</span>
          </div>
          <div class="seating-section">
            <span class="font-size-12 fw-bold">{{ 'loaded.tickets.seat' | translate }} </span>
            <span class="font-size-14"> {{ ticketDetails()!.seat }}</span>
          </div>
        </div>
        <div class="seating-info align-items-center">
          <div class="seating-section">
            <span class="font-size-12 fw-bold">{{ 'loaded.tickets.ticket' | translate }} </span
            ><span class="font-size-14"> {{ ticketDetails()!.acn }}</span>
          </div>
          <div class="">
            <div class="">
              <span class="fw-bold text-success font-size-14">{{
                ticketDetails()!.RealBalanceInPence / 100 | FortressCurrencyPipe
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
}
