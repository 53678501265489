<div class="bg-black h-100 d-flex justify-content-center align-items-center flex-column">
  <img class="logo mb-4" draggable="false" src="assets/images/logo.svg" />
  <div
    *ifScreenSize="screenType.Desktop"
    class="text-white font-size-14 text-center login-desc mb-4"
    [innerHTML]="'login.text.description.desktop' | translate | markdownTranslate"
  ></div>
  <div
    *ifScreenSize="screenType.Mobile"
    class="text-white font-size-14 text-center login-desc mb-4"
    [innerHTML]="'login.text.description' | translate | markdownTranslate"
  ></div>
  <div class="bg-white card">
    <ng-container *ifScreenSize="screenType.Desktop">
      <h5 class="text-black">
        {{ 'login.text.download.app' | translate }}
      </h5>
      <div class="mt-2">
        <div class="apple-google-logos d-flex justify-content-center">
          <a class="pe-3" href="{{ 'apple.store.app.url' | translate }}" target="_blank"
            ><img class="apple-logo" src="/assets/images/passes/apple.svg"
          /></a>
          <a href="{{ 'google.store.app.url' | translate }}" target="_blank"
            ><img class="google-logo" src="/assets/images/passes/google.svg"
          /></a>
        </div>
      </div>
    </ng-container>

    <ng-container *ifScreenSize="screenType.Mobile">
      <div class="w-90">
        <fgb-tm-user-login></fgb-tm-user-login>
      </div>
    </ng-container>
  </div>
</div>
