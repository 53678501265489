<div class="survey-container rounded">
  <div class="survey-header">
    <div class="no-image text-center">
      <h5 class="no-image-title text-primary">{{ 'survey.item.poll.title' | translate }}</h5>
    </div>

    <div class="survey-info">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div class="gIcon-polls material-icons inline-block my-0" aria-hidden="true">assessment</div>
        </div>

        <div class="text-end d-flex" *ngIf="!survey.HasBeenAnsweredByUser">
          <span class="material-icons align-middle pe-1">schedule</span>
          <fgbcl-countdown
            [endDateTime]="survey.EndDate"
            [requestDateTime]="survey.RequestTime"
            [isUTC]="false"
            class="font-size-16 fw-bold"
          ></fgbcl-countdown>
        </div>
      </div>
      <div class="px-1 d-block d-lg-none mt-4 font-size-14 survey-question xs">{{ survey.SurveyQuestion }}</div>
    </div>
  </div>
  <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
    <fgb-survey-item-answer-completed-poll [survey]="survey" [pointsWon]="pointsWon"></fgb-survey-item-answer-completed-poll>
  </ng-container>
  <ng-template #answer>
    <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
  </ng-template>
</div>
