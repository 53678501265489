<form *ngIf="transferCashForm" [formGroup]="transferCashForm">
  <div class="form-group">
    <div class="row">
      <div class="col-lg-5 col-12">
        <label class="font-size-12 fw-bold p-0 mb-2" for="emailAddress">
          {{ 'transfer.credit.email' | translate : { fgbdefault: 'Email Address' } }}
        </label>
        <input
          type="text"
          formControlName="emailAddress"
          id="emailAddress"
          placeholder="Enter recipient's email"
          class="form-control border border-black rounded font-size-14 text-black position-relative"
        />
        <div *ngIf="f.emailAddress.dirty && f.emailAddress.errors" class="alert alert-danger font-size-14">
          <div *ngIf="f.emailAddress.errors.required">
            {{ 'transfer.credit.email.required' | translate : { fgbdefault: 'Email Address is required' } }}
          </div>
          <div *ngIf="f.emailAddress.errors.pattern">
            {{ 'transfer.credit.email.format' | translate : { fgbdefault: 'Email Address format incorrect' } }}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <label class="amount-title font-size-12 fw-bold p-0 mb-2" for="amount">
          {{ 'transfer.credit.amount' | translate : { fgbdefault: 'Amount' } }}
        </label>
        <input
          currencyMask
          formControlName="amount"
          placeholder="{{ 0 | FortressCurrencyPipe : 'USD' }}"
          id="amount"
          class="form-control border border-black rounded font-size-14 text-black position-relative amount-control ml-auto mr-auto"
        />
        <div *ngIf="f.amount.dirty && f.amount.errors" class="alert alert-danger font-size-14">
          <div *ngIf="f.amount.errors.required">
            {{ 'transfer.credit.amount.required' | translate : { fgbdefault: 'Amount is required' } }}
          </div>
          <div *ngIf="f.amount.errors.pattern">
            {{ 'transfer.credit.amount.format' | translate : { fgbdefault: 'Numbers only' } }}
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-12 d-flex justify-content-center">
        <button
          type="submit"
          class="btn btn-send font-size-14 fw-bold text-white bg-primary"
          confirmModal
          [disabled]="!transferCashForm.valid"
          [confirmContent]="confirmContent"
          (confirm)="verifyEmail()"
        >
          {{ 'transfer.credit.send' | translate : { fgbdefault: 'Send' } }}
        </button>
      </div>

      <!--Confirm Modal-->
      <ng-template #confirmContent>
        <div class="py-2 px-4">
          <div class="font-size-14 fw-bold">{{ 'hawks.transfer.modal.title' | translate }}</div>
          <hr />
          <p class="mb-0 text-black fw-bold">
            {{ 'hawks.transfer.modal.confirmation' | translate }}
            <span class="text-success">{{ transferCashForm.value.amount | FortressCurrencyPipe : 'USD' }}</span> to
            <span class="text-primary"> {{ transferCashForm.value.emailAddress }}</span
            >?
          </p>
        </div>
      </ng-template>
    </div>

    <!-- Can't Transfer to Self Modal -->
    <ng-template #transferOwnEmail let-modal>
      <div class="modal-body">
        <div class="modal-title text-center mt-3 fw-bold">
          {{ 'transfer.credit.failed' | translate }}
        </div>
        <p class="mb-1 p-4 font-size-16 text-black text-center fw-bold">
          {{ 'transfer.credit.failed.self' | translate }}
        </p>
        <button
          type="button"
          class="modal-return rounded d-block ml-auto mr-auto bg-primary text-white font-size-14 fw-bold text-center border-0 fail"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          {{ 'transfer.credit.failed.back' | translate }}
        </button>
      </div>
    </ng-template>

    <!-- Transfer Fail Modal -->
    <ng-template #transferFailed let-modal>
      <div class="modal-body">
        <div class="modal-title text-center mt-3 fw-bold">
          {{ 'transfer.credit.failed' | translate }}
        </div>
        <p class="mb-1 p-4 font-size-16 text-black text-center fw-bold">
          {{ 'transfer.credit.failed.details' | translate }}
        </p>
        <button
          type="button"
          class="modal-return rounded d-block ml-auto mr-auto bg-primary text-white font-size-14 fw-bold text-center border-0 fail"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          {{ 'transfer.credit.failed.back' | translate }}
        </button>
      </div>
    </ng-template>

    <!-- Not Enough Funds Modal -->
    <ng-template #invalidFunds let-modal>
      <div class="modal-body">
        <p class="m-4 font-size-14 text-black text-center fw-bold">
          {{ 'transfer.credit.failed.send.to' | translate }}
          <span class="text-success">{{ transferCashForm.value.emailAddress }}</span>
          {{ 'transfer.credit.failed.send.amount' | translate }}
          <span class="text-primary">{{ transferCashForm.value.amount | FortressCurrencyPipe : 'USD' }}</span>
        </p>
        <button
          type="button"
          class="modal-return rounded d-block ml-auto mr-auto bg-primary text-white font-size-14 fw-bold text-center border-0"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          {{ 'transfer.credit.return' | translate : { fgbdefault: 'Return to Transfer Credit' } }}
        </button>
      </div>
    </ng-template>

    <fgb-errors></fgb-errors>

    <ng-container *ngIf="loading">
      <div class="loading-transactions">
        <fgb-loading></fgb-loading>
      </div>
    </ng-container>
  </div>
</form>
