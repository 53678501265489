<div class="container">
  <div class="d-flex pt-3">
    <div class="text-danger cursor-pointer back-button" (click)="backButton()">
      <span class="material-icons align-middle fw-bold pe-2">navigate_before</span>
      <span class="font-size-14 fw-bold">{{ 'campaign.back.to.wallet' | translate }}</span>
    </div>
    <!-- Image -->
    <div class="loyalty-details-image rounded overflow-hidden p-0">
      <img
        [ngClass]="{
          'image-opacity': currentCampaign.campaignRedemptions_Quantity == 0,
        }"
        alt=""
        [src]="currentCampaign.image_URL"
        class="logo"
      />
    </div>
    <div class="back-button icon-placement">
      <ng-container *ngIf="currentCampaign.campaignRedemptions_Quantity == 0">
        <span class="back-button icon material-icons text-grey text-end fw-bold ms-3"> lock </span>
      </ng-container>
      <ng-container *ngIf="currentCampaign.campaignRedemptions_Quantity > 0">
        <span class="back-button icon material-icons text-success text-end fw-bold ms-3"> check_circle </span>
      </ng-container>
    </div>
  </div>
  <!-- Name -->
  <div class="text-center font-size-14 fw-bold my-3">{{ currentCampaign.CampaignName }}</div>
  <!-- Expiry -->
  <div class="font-size-12 text-danger text-center pt-lg-2 pt-0">
    {{ 'offer.expiry' | translate }} {{ currentCampaign.Campaign_ExpiryDate | date: 'MMM dd, yyyy' }}
  </div>
  <!-- Description -->
  <hr class="border-top border-disabled my-3" />
  <label class="pt-2">{{ 'offer.description' | translate }}</label>
  <div class="font-size-14">{{ currentCampaign.PortalDescription }}</div>
</div>
